import { SquarePatternRoundedBG } from '@components/UI/SquarePattern';
import { Box, Container, Flex, Grid, GridItem, Heading, Text } from '@terminal/design-system';
import { SpotLightContentStoryblok, SpotlightStoryblok, Storyblok } from '../types';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import Image from 'next/image';
import {
  forceExtractDimensionsFormURL,
  selectBlokStyles,
  selectResponsiveStyle,
} from '@helpers/storyblok.utils';

export function SpotlightContent({ blok, page }: Storyblok<SpotLightContentStoryblok>) {
  return (
    <Box {...storyblokEditable(blok)}>
      <Heading as="h2" variant="heading-2" mb={1}>
        {blok.title}
      </Heading>
      <Text variant="label" color="text.secondary" lineHeight="short" mb={1}>
        {blok.description}
      </Text>
      <StoryblokComponent
        blok={{
          ...blok,
          component: 'richText',
          content: blok.longDescription,
        }}
        page={page}
        key={blok._uid}
      />
      <Box mt={3}>
        {(blok.list || [])?.map((listBlok) => (
          <StoryblokComponent blok={listBlok} key={listBlok._uid} page={page} />
        ))}

        {blok?.cta && (
          <Flex flexDir={['column', 'row', 'row']} gap={4} mt={2}>
            {(blok?.cta || [])?.map((eachCTA) => (
              <StoryblokComponent blok={eachCTA} key={eachCTA._uid} page={page} />
            ))}
          </Flex>
        )}
      </Box>
    </Box>
  );
}

export function Spotlight({ blok, page }: Storyblok<SpotlightStoryblok>) {
  const { marginStyles, paddingStyles } = selectBlokStyles(blok);

  const imageDimensions = forceExtractDimensionsFormURL(blok.image?.filename, {
    width: 2000,
    height: 800,
  });

  imageDimensions.width =
    (blok.imageWidth && !Number.isNaN(Number(blok?.imageWidth))
      ? Number(blok?.imageWidth)
      : imageDimensions.width) ?? imageDimensions.width;

  imageDimensions.height =
    (blok.imageHeight && !Number.isNaN(Number(blok?.imageHeight))
      ? Number(blok?.imageHeight)
      : imageDimensions.height) ?? imageDimensions.height;

  const dotted = {
    width: selectResponsiveStyle(blok.dottedWidth),
    height: selectResponsiveStyle(blok.dottedHeight),
    show: blok.showDottedBackground,
    top: selectResponsiveStyle(blok.dottedTopPosition),
    right: selectResponsiveStyle(blok.dottedRightPosition),
    bottom: selectResponsiveStyle(blok.dottedBottomPosition),
    left: selectResponsiveStyle(blok.dottedLeftPosition),
  };

  return (
    <Box
      my={10}
      {...marginStyles}
      {...storyblokEditable(blok)}
      overflow="hidden"
      position="relative"
      paddingTop={paddingStyles.paddingTop || { base: 4, lg: 10 }}
      paddingBottom={paddingStyles.paddingBottom || { base: 4, lg: 10 }}
    >
      <Container>
        <Grid
          gridTemplateColumns={{ lg: '1fr 1fr' }}
          gap={blok.gridGap ? selectResponsiveStyle(blok.gridGap) : { base: 7, lg: 4 }}
          justifyContent={{ base: 'center', lg: 'start' }}
        >
          <GridItem maxW="34.25rem" display="flex" flexDir="column" justifyContent="center">
            <Flex mb={8} gridGap={2}>
              <Box w={2} h={2} bgColor="blue.500" />
              <Box w={2} h={2} bgColor="yellow.400" />
              <Box w={2} h={2} bgColor="green.500" />
            </Flex>
            {(blok.content || [])?.map((eachBlok) => (
              <StoryblokComponent blok={eachBlok} key={eachBlok._uid} page={page} />
            ))}
            {blok?.cta && (
              <Flex flexDir={['column', 'row', 'row']} gap={4} mt={8}>
                {(blok?.cta || [])?.map((eachCTA) => (
                  <StoryblokComponent blok={eachCTA} key={eachCTA._uid} page={page} />
                ))}
              </Flex>
            )}
          </GridItem>
          <GridItem
            position="relative"
            order={{
              base: -1,
              lg: blok.type === 'imageRight' ? 'initial' : '-1',
            }}
            py={3}
          >
            <Box
              pos="relative"
              mx={{
                base: 'auto',
                lg: blok.type === 'imageRight' ? 'auto' : 'initial',
              }}
            >
              <Image
                src={blok.image?.filename}
                alt={blok.image.alt}
                width={imageDimensions?.width}
                height={imageDimensions?.height}
              />
            </Box>
            {blok?.showSquarePattern && (
              <Box
                pos="absolute"
                w="100vw"
                display="inline-flex"
                bgColor={blok?.squareColor?.color}
                left={{
                  base: blok.type === 'imageLeft' ? 'initial' : '50%',
                  lg: blok?.squarePositionLeft,
                }}
                right={{
                  base: blok.type === 'imageLeft' ? '50%' : 'initial',
                  lg: blok?.squarePositionRight,
                }}
                top={{
                  base: '-0.8rem',
                  lg: blok?.squarePositionTop,
                }}
                h={{
                  base: '110%',
                  lg: blok?.squarePositionHeight,
                }}
                zIndex={-1}
              />
            )}
          </GridItem>
        </Grid>
      </Container>

      {dotted.show && (
        <SquarePatternRoundedBG
          display={{ base: 'none', lg: 'initial' }}
          pos="absolute"
          top={dotted.top || 'initial'}
          bottom={dotted.bottom || '0'}
          left={dotted.left || (blok.type === 'imageLeft' ? '0' : 'initial')}
          right={dotted.right || (blok.type === 'imageLeft' ? 'initial' : '0')}
          w={dotted.width || '40%'}
          h={dotted.height || '94%'}
          zIndex={-2}
        />
      )}
    </Box>
  );
}
