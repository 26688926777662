import { LinkProps } from '@chakra-ui/react';
import { Heading, Container, Box, Link, Text, linkTheme } from '@terminal/design-system';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import Image from 'next/image';
import {
  NODE_OL,
  NODE_UL,
  MARK_LINK,
  NODE_HEADING,
  NODE_IMAGE,
  NODE_PARAGRAPH,
  render,
} from 'storyblok-rich-text-react-renderer';
import { forceExtractDimensionsFormURL, selectBlokStyles } from '@helpers/storyblok.utils';
import type { RichTextStoryblok, StoryblokPage } from '../types';

export const StyledLink = ({ children, ...rest }: LinkProps) => {
  const baseStyle = {
    display: 'inline-flex',
    lineHeight: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    color: 'inherit',
    textDecoration: 'underline',
  };
  return (
    <Link
      {...rest}
      href={rest.href}
      sx={{
        // This overwriting is required, because of the way the rich text-editor adds customizations with span tags
        '& > *': {
          ...linkTheme.baseStyle({ colorScheme: 'text' }),
          ...baseStyle,
          _hover: {
            // This overwriting is required, because of the way the rich text-editor adds customizations with span tags
            color: 'accent.darkest!important',
          },
          _focus: {
            // This overwriting is required, because of the way the rich text-editor adds customizations with span tags
            color: 'accent.darkest!important',
          },
        },
        ...baseStyle,
        ...rest.sx,
      }}
    >
      {children}
    </Link>
  );
};

// https://github.com/claus/storyblok-rich-text-react-renderer
export function RichText(props: {
  blok?: RichTextStoryblok;
  document?: RichTextStoryblok['content'];
  page: StoryblokPage;
}) {
  // document is the rich text object you receive from Storyblok,
  // in the form { type: "doc", content: [ ... ] }

  const { marginStyles, sizesStyle } = selectBlokStyles(props.blok);

  let editor = props?.blok?.content; // if used as a blok

  // if used as a page property
  if (!editor) {
    editor = props?.document;
  }

  const displayContent = render(editor, {
    markResolvers: {
      [MARK_LINK]: (children, props) => {
        const { linktype, href, target } = props;
        if (linktype === 'email') {
          // Email links: add `mailto:` scheme and map to <a>
          return <StyledLink href={`mailto:${href}`}>{children}</StyledLink>;
        }
        if (href?.match(/^(https?:)?\/\//)) {
          // External links: map to <a>
          return (
            <StyledLink href={href} target={target}>
              {children}
            </StyledLink>
          );
        }
        // Internal links: map to <Link>
        return <StyledLink href={href}>{children}</StyledLink>;
      },
    }, // inline elements
    nodeResolvers: {
      [NODE_OL]: (children) => <ol style={{ marginInlineStart: '1.1rem' }}>{children}</ol>,
      [NODE_UL]: (children) => <ul style={{ marginInlineStart: '1.1rem' }}>{children}</ul>,
      [NODE_IMAGE]: (children, { src, alt, title }) => {
        const { width, height } = forceExtractDimensionsFormURL(src, { width: 1200, height: 800 });

        return (
          <Image
            src={src}
            alt={alt}
            title={title}
            width={width}
            height={height}
            style={{
              objectFit: 'contain',
              display: 'inline-block',
              position: 'relative',
              maxWidth: '100%',
            }}
          />
        );
      },
      [NODE_HEADING]: (children, { level }) => {
        return (
          // @ts-ignore
          <Heading as={`h${level}`} mb={2} variant={`heading-${level}`}>
            {children}
          </Heading>
        );
      },
      [NODE_PARAGRAPH]: (children) => <Text as="p">{children}</Text>,
    }, // block elements
    defaultBlokResolver: (name, _props) => {
      const blok = { ...props, ..._props, component: name };
      return (
        <StoryblokComponent
          {...storyblokEditable(blok)}
          page={props.page}
          blok={blok}
          key={_props._uid}
        />
      );
    },
  });

  if (props.blok?.withPadding) {
    return (
      <Container {...marginStyles} {...sizesStyle} {...storyblokEditable(props?.blok)}>
        {displayContent}
      </Container>
    );
  }
  return (
    <Box {...marginStyles} {...sizesStyle} {...storyblokEditable(props?.blok)}>
      {displayContent}
    </Box>
  );
}
