import { Text as _Text } from '@terminal/design-system';
import { Storyblok, TextStoryblok } from '../types';

export function Text({ blok }: Storyblok<TextStoryblok>) {
  const { variant, text, align } = blok;

  return (
    <_Text
      variant={variant === 'body-large' ? 'body' : variant || 'body'}
      align={align || 'left'}
      style={variant === 'body-large' ? { fontSize: '20px' } : {}}
    >
      {text}
    </_Text>
  );
}
