import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';

export function useSetUtmCookies() {
  const router = useRouter();

  const utmCampaign = () =>
    router.query.utm_campaign ? `utm_campaign=${router.query.utm_campaign}` : '';
  const utmMedium = () => (router.query.utm_medium ? `utm_medium=${router.query.utm_medium}` : '');
  const utmSource = () => (router.query.utm_source ? `utm_source=${router.query.utm_source}` : '');
  const utmContent = () =>
    router.query.utm_content ? `utm_content=${router.query.utm_content}` : '';
  const utmTerm = () => (router.query.utm_term ? `utm_term=${router.query.utm_term}` : '');
  const cid = () => (router.query.cid ? `cid=${router.query.cid}` : '');
  const trackingId = () =>
    router.query.tracking_id ? `tracking_id=${router.query.tracking_id}` : '';

  const allUtms = Array.from([
    utmCampaign(),
    utmMedium(),
    utmSource(),
    utmContent(),
    utmTerm(),
    cid(),
    trackingId(),
  ]);

  const utmParams = allUtms.filter((e) => e).join('&');
  // first touch utms
  utmParams &&
    !Cookies.get('firstTouchParams') &&
    Cookies.set('firstTouchParams', utmParams, { expires: 365 });
  // last touch utms
  utmParams && Cookies.set('lastTouchParams', utmParams, { expires: 365 });
}

export function useGetUtmCookies() {
  const [utmParams, setUtmParams] = useState<string>('');

  const firstTouchParamsCookies: string = Cookies.get('firstTouchParams') ?? '';
  const firstTouchParams: string = firstTouchParamsCookies
    ? firstTouchParamsCookies.replace(/utm/g, 'ft_utm').replace(/cid/g, 'ft_cid')
    : '';
  const lastTouchParams: string = Cookies.get('lastTouchParams') ?? '';
  const allUtms = Array.from([firstTouchParams, lastTouchParams]);
  const allParams = allUtms.filter((e) => e).join('&');

  useEffect(() => {
    if (allParams) {
      setUtmParams(`?${allParams}`);
    }
  }, [allParams]);

  return utmParams;
}
