import { Container, Grid, GridItem } from '@chakra-ui/react';
import { SimpleSpotlightStoryblok, Storyblok } from '../types';
import Image from 'next/image';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { useMemo } from 'react';

export function SimpleSpotlight({ blok, page }: Storyblok<SimpleSpotlightStoryblok>) {
  const imagePosition = blok.imagePosition || 'left';
  const fullWidth = blok.fullWidth || false;
  const bgColor = useMemo(
    () => ({
      content: {
        bgColor: blok.backgroundBox === 'content' ? blok.backgroundColor.value : 'transparent',
      },
      image: {
        bgColor: blok.backgroundBox === 'image' ? blok.backgroundColor.value : 'transparent',
      },
      full: {
        bgColor: blok.backgroundBox === 'full' ? blok.backgroundColor.value : 'transparent',
      },
    }),
    [blok.backgroundBox, blok.backgroundColor],
  );

  const _SimpleSpotlight = (props) => (
    <Grid
      gridTemplateColumns={{
        base: '1fr',
        lg:
          `${blok.leftCardSizePercentage}fr ${100 - Number(blok.leftCardSizePercentage)}fr` ||
          '1fr 1fr',
      }}
      gap={{ base: 0, lg: 8 }}
      paddingY={{ base: 6 }}
      paddingX={blok.backgroundBox === 'full' ? { base: 0, sm: 6 } : 0}
      {...bgColor.full}
      {...props}
    >
      <GridItem
        order={{ base: 1, md: imagePosition === 'right' ? 2 : 1 }}
        padding={{ base: 4 }}
        position={'relative'}
        overflow={'hidden'}
        w={'100%'}
        h={{ base: '18rem', sm: '20rem', md: '30rem', lg: '100%' }}
        {...bgColor.image}
      >
        {/* Image */}
        <Image
          src={blok.image?.filename}
          alt={blok.image.alt}
          layout="fill"
          objectFit={blok.imageObjectFit}
        />
      </GridItem>
      <GridItem
        order={{ base: 2, md: imagePosition === 'right' ? 1 : 2 }}
        padding={{ base: 4, md: 12 }}
        {...bgColor.content}
      >
        {/* Content */}
        {(blok.content || [])?.map((eachBlok) => (
          <StoryblokComponent blok={eachBlok} key={eachBlok._uid} page={page} />
        ))}
      </GridItem>
    </Grid>
  );

  if (fullWidth) {
    return <_SimpleSpotlight {...storyblokEditable(blok)} />;
  }

  return (
    <Container {...storyblokEditable(blok)}>
      <_SimpleSpotlight />
    </Container>
  );
}
