import Image, { type ImageProps } from 'next/image';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { Box, Flex, Link } from '@terminal/design-system';
import { forceExtractDimensionsFormURL, selectBlokStyles } from '@helpers/storyblok.utils';
import type { CardStoryblok, Storyblok } from '../types';
import { radii } from '@terminal/global/design-system/theme/design-tokens';

function createImageStyleByPosition(
  backgroundImagePosition: CardStoryblok['backgroundImagePosition'],
) {
  const styles = {
    position: 'absolute',
    bottom: 'initial',
    left: 'initial',
    right: 'initial',
    top: 'initial',
    margin: 'auto',
  } as const;

  if (backgroundImagePosition === 'full') {
    return {
      ...styles,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    };
  } else {
    backgroundImagePosition.split('_').forEach((property) => {
      styles[property] = 0;
    });
  }

  return styles;
}

function createBorderStyle(border: CardStoryblok['rounded'], borderWidth) {
  return {
    borderStyle: 'solid',
    borderWidth: borderWidth + 'px' || '1px',
    borderColor: 'ui.secondary',
    borderRadius: border,
  };
}

const shadowStyles = { boxShadow: 'rgba(28, 40, 45, 0.2) 0px 0.125rem 0.5rem' };

export function Card(props: Storyblok<CardStoryblok>) {
  const { blok, page } = props;
  const { paddingStyles, sizesStyle, flexStyles } = selectBlokStyles(blok);

  const borderProps = createBorderStyle(blok.rounded, blok.borderWidth);
  const shadowProps = blok.shadow ? shadowStyles : {};
  const backgroundColor = blok?.backgroundColor?.value || 'transparent';

  const imageDimensions = forceExtractDimensionsFormURL(blok?.backgroundImage?.filename, {
    width: 256,
    height: 256,
  });

  const _Card = (props) => (
    <Flex {...props}>
      <Box zIndex={'docked'} height={'100%'}>
        {(blok.content || [])?.map((eachCTA) => (
          <StoryblokComponent blok={eachCTA} key={eachCTA._uid} page={page} />
        ))}
      </Box>
      {blok.backgroundImage?.filename ? (
        <Box zIndex={'base'}>
          <Image
            src={blok.backgroundImage.filename}
            width={imageDimensions.width}
            height={imageDimensions.height}
            alt={blok.backgroundImage?.alt || 'Background Image'}
            style={{
              ...createImageStyleByPosition(blok.backgroundImagePosition),
              borderRadius:
                blok.backgroundImagePosition === 'full' ? radii[borderProps.borderRadius] : 'none',
            }}
          />
        </Box>
      ) : null}
    </Flex>
  );

  const commpnProps = {
    ...borderProps,
    ...paddingStyles,
    ...sizesStyle,
    ...shadowProps,
    ...storyblokEditable(blok),
    flexDirection: blok?.cardDirection || 'column',
    bgColor: backgroundColor,
    justifyContent: flexStyles.justifyContent || 'center',
    alignItems: flexStyles.alignItems || 'center',
  };

  if (blok.cta) {
    return (
      <Link
        href={blok.cta.url}
        {...commpnProps}
        _hover={{
          textDecoration: 'none',
          backgroundColor: 'ui.secondary',
        }}
      >
        <_Card />
      </Link>
    );
  }

  return <_Card {...commpnProps} position={'relative'} />;
}
