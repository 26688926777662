import { ReactNode, useEffect } from 'react';
import Head from 'next/head';
import Cookies from 'js-cookie';

import favicon from '../public/favicon.ico';
import { IntercomPerformanceOptimized } from '../lib/intercom';

import Header from './Header/Header';
import Footer from './Footer/Footer';
import { getContext, useSegment } from '@lib/segment/SegmentContext';
import Script from 'next/script';

export default function Layout({
  children,
  type,
  footer,
  withCTA,
  getStartedLink,
  hideTopFooter,
  hideBanner,
}: {
  children: ReactNode;
  type: 'businesses' | 'engineers';
  footer?: {
    longText: boolean;
    text: string;
    image?: string;
    button?: {
      url: string;
      text: string;
    };
    outlineButton?: {
      url: string;
      text: string;
    };
  };
  withCTA: boolean;
  getStartedLink?: string;
  hideTopFooter?: boolean;
  hideBanner?: boolean;
}) {
  const segment = useSegment();

  useEffect(() => {
    if (segment.canTrackUser) {
      segment.trackPage({
        category: type,
        name: document.title,
      });
    }
  }, [segment, type]);

  // for Engineers redirect set cookie so on home page it will not redirect
  // expires in 1 hour

  !Cookies.get('nextCheckedLocation') &&
    Cookies.set('nextCheckedLocation', true, { expires: 1 / 24 });

  if (type === 'engineers') {
    return (
      <>
        <Head>
          <link rel="shortcut icon" href={favicon.src} />
        </Head>
        <Header type={type} getStartedLink={getStartedLink} hideBanner={hideBanner} />
        <IntercomPerformanceOptimized type={type} />
        {children}
        <Footer type={type} footer={footer} withCTA={withCTA} hideTopFooter={hideTopFooter} />
      </>
    );
  }

  return (
    <>
      <Head>
        <link rel="shortcut icon" href={favicon.src} />
      </Head>
      <Header type={type} getStartedLink={getStartedLink} hideBanner={hideBanner} />
      {children}

      <Script
        id="reb2b"
        async
        strategy="afterInteractive"
      >{`!function () {var reb2b = window.reb2b = window.reb2b || []; if (reb2b.invoked) return;reb2b.invoked = true;reb2b.methods = ["identify", "collect"]; reb2b.factory = function (method) {return function () {var args = Array.prototype.slice.call(arguments); args.unshift(method);reb2b.push(args);return reb2b;};}; for (var i = 0; i < reb2b.methods.length; i++) {var key = reb2b.methods[i];reb2b[key] = reb2b.factory(key);} reb2b.load = function (key) {var script = document.createElement("script");script.type = "text/javascript";script.async = true; script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/reb2b.js.gz"; var first = document.getElementsByTagName("script")[0]; first.parentNode.insertBefore(script, first);}; reb2b.SNIPPET_VERSION = "1.0.1";reb2b.load("4O7Z0HMMLJNX");}();`}</Script>

      <Footer type={type} footer={footer} withCTA={withCTA} hideTopFooter={hideTopFooter} />
    </>
  );
}
