import { storyblokEditable } from '@storyblok/react';
import { Container, Box } from '@terminal/design-system';
import type { TestimonialSectionStoryblok, Storyblok } from '../types';
import { selectBlokStyles } from '@helpers/storyblok.utils';
import Testimonial from '@components/UI/Testimonial';

export function TestimonialSection({ blok }: Storyblok<TestimonialSectionStoryblok>) {
  const WrapperComponent = blok.isFullWidth ? Box : Container;
  const { marginStyles } = selectBlokStyles(blok);

  return (
    <WrapperComponent
      as="section"
      my="0"
      p={0}
      {...marginStyles}
      {...storyblokEditable(blok)}
      {...(blok.isFullWidth ? { bgColor: blok.isDark ? 'grey.900' : 'bg.tertiary' } : {})}
    >
      <Container p={0}>
        <Testimonial
          testimonial={{
            bgDark: blok.isDark,
            image: {
              sourceUrl: blok.authorPhoto.filename,
              altText: blok.authorPhoto.alt,
            },
            text: blok.message,
            author: {
              name: blok.author,
              jobTitle: blok.authorDescription,
            },
          }}
          pt="16"
          pb="20"
          px={{ base: '16', lg: '20' }}
        />
      </Container>
    </WrapperComponent>
  );
}
