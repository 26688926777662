import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { Storyblok, RichTextWithCopyStoryblok } from '../types';
import { Box, Button, Flex, Heading } from '@terminal/design-system';
import Image from 'next/image';
import { MouseEventHandler } from 'react';
import {
  NODE_OL,
  NODE_UL,
  MARK_LINK,
  NODE_HEADING,
  NODE_IMAGE,
  NODE_PARAGRAPH,
  render,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKE,
  MARK_UNDERLINE,
  MARK_CODE,
  NODE_CODEBLOCK,
  NODE_QUOTE,
  NODE_LI,
  NODE_HR,
  NODE_BR,
  MARK_STYLED,
} from 'storyblok-rich-text-react-renderer';
import { selectBlokStyles } from '@helpers/storyblok.utils';
export function RichTextWithCopy({ blok, page }: Storyblok<RichTextWithCopyStoryblok>) {
  const { title } = blok;
  const { marginStyles, sizesStyle } = selectBlokStyles(blok);
  const editor = blok.editor[0].content;
  // we want to be able to get only the text content
  const copyContent = render(editor, {
    markResolvers: {
      // @ts-ignore
      [MARK_BOLD]: (children) => `<strong>${children}</strong>`,

      // @ts-ignore
      [MARK_ITALIC]: (children) => `<em>${children}</em>`,

      // @ts-ignore
      [MARK_STRIKE]: (children) => `<del>${children}</del>`,

      // @ts-ignore
      [MARK_UNDERLINE]: (children) => `<u>${children}</u>`,

      // @ts-ignore
      [MARK_CODE]: (children) => `\`${children}\``,

      // @ts-ignore
      [MARK_STYLED]: (children) => `${children}`,

      // @ts-ignore
      [MARK_LINK]: (children, { href }) => `<a href=${href}>${children}</a>`,
    },
    nodeResolvers: {
      // @ts-ignore
      [NODE_HEADING]: (children, { level }) => `<h${level}>${children}</h${level}>`,

      // @ts-ignore
      [NODE_CODEBLOCK]: (children) => `<pre>${children}</pre>`,

      // @ts-ignore
      [NODE_PARAGRAPH]: (children) => (children ? `<p>${children}</p>` : ''),

      // @ts-ignore
      [NODE_QUOTE]: (children) => children,

      // @ts-ignore
      [NODE_OL]: (children) => `<ol>${children}</ol>`,

      // @ts-ignore
      [NODE_UL]: (children) => `<ul>${children}</ul>`,

      // @ts-ignore
      [NODE_LI]: (children) => `${children}`,

      // @ts-ignore
      [NODE_IMAGE]: (children) => '',

      // @ts-ignore
      [NODE_HR]: (children) => '',

      // @ts-ignore
      [NODE_BR]: (children) => `<br />`,
    },
  });

  const handleCopy: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    /**
     * Helper function to replace list tags with html/markdown list tags
     * this is because list items are comma separated in the output.
     */
    const replaceListTags = (
      item: string,
      tag: string,
      mapFn: (el: string, index: number) => string,
      replaceTag = false,
    ): string => {
      if (new RegExp(`<${tag}>.*</${tag}>`, 'g').test(item)) {
        const listItems = item.match(new RegExp(`<${tag}>(?<items>.*)</${tag}>`));
        item = listItems.groups.items.split(',').map(mapFn).join('');
        if (!replaceTag) {
          item = `<${tag}>${item}</${tag}>`;
        }
      }
      return item;
    };

    // convert lists to proper html markup
    const copyContentHtml: string = copyContent
      .map((item: Array<string> | string) => {
        if (typeof item === 'string') {
          item = replaceListTags(item, 'ol', (el) => `<li>${el}</li>`);
          item = replaceListTags(item, 'ul', (el) => `<li>${el}</li>`);
        }
        return item;
      })
      .join('');

    // convert html to markdown
    const copyContentMarkdown: string = copyContent
      .map((item: Array<string> | string) => {
        if (typeof item === 'string') {
          item = replaceListTags(item, 'ol', (el, index) => `${index + 1}. ${el}\n`, true);
          item = replaceListTags(item, 'ul', (el) => `- ${el}\n`, true);
          if (item.match(/<h(\d)>/g)) {
            item = item.replace(/<h1>/g, '# ');
            item = item.replace(/<h2>/g, '## ');
            item = item.replace(/<h3>/g, '### ');
            item = item.replace(/<h4>/g, '#### ');
            item = item.replace(/<h5>/g, '##### ');
            item = item.replace(/<h6>/g, '###### ');
            item = item.replace(/<\/h\d>/g, '');
          }
          item = item.replace(/<br \/>/g, '\n');
          item = item.replace(/<p>/g, '');
          item = item.replace(/<\/p>/g, '');
          item = item.replace(/<strong>/g, '**');
          item = item.replace(/<\/strong>/g, '**');
          item = item.replace(/<em>/g, '*');
          item = item.replace(/<\/em>/g, '*');
          item = item.replace(/<u>/g, '__');
          item = item.replace(/<\/u>/g, '__');
          item = item.replace(/<del>/g, '~~');
          item = item.replace(/<\/del>/g, '~~');
          item = item.replace(/<pre>/g, '`');
          item = item.replace(/<\/pre>/g, '`');
          item = item.replace(/<a href=(.*)>(.*)<\/a>/g, '[$2]($1)');
        }
        return item;
      })
      .join('\n');

    navigator.clipboard.write([
      new ClipboardItem({
        'text/html': Promise.resolve(new Blob([copyContentHtml], { type: 'text/html' })),
        'text/plain': Promise.resolve(new Blob([copyContentMarkdown], { type: 'text/plain' })),
      }),
    ]);
  };

  return (
    <Flex
      flexDirection={'column'}
      backgroundColor={'bg.primary'}
      borderRadius={'base'}
      borderColor={'ui.secondary !important'}
      border={'1px solid'}
      {...marginStyles}
      {...sizesStyle}
      {...storyblokEditable(blok)}
    >
      <Flex
        justifyContent={'space-between'}
        flexDirection={['column', 'column', 'row']}
        alignItems={['flex-start', 'flex-start', 'center']}
        backgroundColor={'bg.tertiary'}
        borderBottom={'1px solid'}
        borderColor={'ui.secondary'}
        padding={4}
      >
        <Heading as="h2" variant="heading-2">
          {title}
        </Heading>
        <Button
          variant={'ghost'}
          leftIcon={<Image src="/images/ui/copy.svg" alt="copy" width={20} height={20} />}
          onClick={handleCopy}
          paddingLeft={[0, 0, 2]} // left align with the title text
        >
          Copy
        </Button>
      </Flex>
      {blok.editor.map((nestedBlok) => (
        <Box padding={4}>
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} page={page} />
        </Box>
      ))}
    </Flex>
  );
}
