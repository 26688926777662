import { Heading as DesignSystemHeading } from '@terminal/design-system';
import { HeadingStoryblok, Storyblok } from '../types';
import { storyblokEditable } from '@storyblok/react';

export function Heading({ blok }: Storyblok<HeadingStoryblok>) {
  const { text = '', level, align } = blok;

  return (
    <DesignSystemHeading
      {...storyblokEditable(blok)}
      as={`h${level || '2'}`}
      variant={`heading-${level || '2'}`}
      textAlign={align || 'center'}
    >
      {text}
    </DesignSystemHeading>
  );
}
