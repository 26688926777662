import type {
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleObject,
} from '@chakra-ui/theme-tools';
import { tableAnatomy as parts } from '@chakra-ui/anatomy';

import { textTheme } from '../Text/Text.theme';

const baseStyle: PartsStyleObject<typeof parts> = {
  table: {
    fontVariantNumeric: 'body',
    borderCollapse: 'collapse',
    width: 'full',
  },
  th: {
    ...textTheme.variants.label,
    textTransform: 'capitalize',
    letterSpacing: 'normal',
    textAlign: 'start',
  },
  td: {
    ...textTheme.variants.body,
  },
  caption: {
    ...textTheme.variants.caption,
    textAlign: 'center',
    fontWeight: 'medium',
  },
};

const numericStyles: SystemStyleObject = {
  '&[data-is-numeric=true]': {
    textAlign: 'start',
  },
};

const variantSimple: PartsStyleFunction<typeof parts> = () => {
  return {
    th: {
      color: 'text.secondary',
      borderBottom: '1px',
      borderColor: 'ui.secondary',
      ...numericStyles,
    },
    tr: {
      borderBottom: '1px',
      borderColor: 'ui.inverse.secondary',
      '&:last-child': { borderBottom: '0' },
    },
    td: {
      color: 'text.primary',
      borderBottom: '0',
      ...numericStyles,
    },
    caption: {},
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 },
        },
      },
    },
  };
};

const variants = {
  simple: variantSimple,
  striped: {},
  unstyled: {},
};

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  sm: {
    th: {
      ...textTheme.variants.label,
      px: '3',
      py: '4',
      minH: '12',
    },
    td: {
      ...textTheme.variants.body,
      px: '3',
      py: '4',
      minH: '12',
    },
    caption: {
      ...textTheme.variants.caption,
      px: '3',
      py: '4',
      minH: '12',
    },
  },
  md: {
    th: {
      ...textTheme.variants.label,
      color: 'red',
      p: '4',
      minH: '14',
    },
    td: {
      ...textTheme.variants.caption,
      p: '4',
      minH: '14',
    },
    caption: {
      ...textTheme.variants.caption,
      p: '4',
      minH: '14',
    },
  },
  lg: {
    th: {
      ...textTheme.variants.label,
      p: '4',
      minH: '16',
    },
    td: {
      ...textTheme.variants.body,
      p: '4',
      minH: '16',
    },
    caption: {
      ...textTheme.variants.caption,
      p: '4',
      minH: '16',
    },
  },
};

const defaultProps = {
  variant: 'simple',
  size: 'md',
};

export const tableTheme = {
  parts: parts.keys,
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
