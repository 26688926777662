// @ts-ignore
import { _ as mpUtil } from 'mixpanel-browser';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import getConfig from 'next/config';
import Router from 'next/router';
import { SegmentConsent, requiresConsent } from './SegmentConsent';

const { publicRuntimeConfig } = getConfig();

/**
 * Checks if the browser supports the Local Storage API
 * From modernizr - https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/localstorage.js
 * @returns {boolean} - True if Local Storage is supported, false otherwise
 */
function isLocalStorageSupported() {
  const testKey = 'test';
  try {
    localStorage.setItem(testKey, '1');
    localStorage.removeItem(testKey);
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * This function creates and manages campaign UTC parameters.
 * @function
 * @param {Object} campaignParams - An object containing the following properties:
 *   - utm_campaign {string}
 *   - utm_source {string}
 *   - utm_medium {string}
 *   - utm_term {string}
 *   - utm_content {string}
 * @returns {Object} - An object containing the campaign parameters, either from the provided campaignParams object or from session storage if available.
 */
function createCampaignParams(campaignParams: {
  utm_campaign?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_term?: string;
  utm_content?: string;
}) {
  // Check if session storage is available
  if (!isLocalStorageSupported()) {
    return campaignParams || {};
  }

  const oldCampaignInfoInSession = JSON.parse(localStorage.getItem('campaignInfo')) || {};

  // If no new campaign params are provided, return the old campaign info from session storage
  if (!campaignParams || Object.entries(campaignParams).length === 0) {
    return oldCampaignInfoInSession;
  }

  // If there is old campaign info in session storage, merge it with the new campaign info and return the result
  const newCampaignInfoInSession = {
    utm_campaign: campaignParams.utm_campaign || oldCampaignInfoInSession.utm_campaign,
    utm_source: campaignParams.utm_source || oldCampaignInfoInSession.utm_source,
    utm_medium: campaignParams.utm_medium || oldCampaignInfoInSession.utm_medium,
    utm_term: campaignParams.utm_term || oldCampaignInfoInSession.utm_term,
    utm_content: campaignParams.utm_content || oldCampaignInfoInSession.utm_content,
  };

  localStorage.setItem('campaignInfo', JSON.stringify(newCampaignInfoInSession));

  return newCampaignInfoInSession;
}

export const getContext = () => {
  const mpProperties = mpUtil.info.properties();
  const campaignParams = createCampaignParams(mpUtil.info.campaignParams());

  return {
    os: {
      name: mpProperties.$os || null,
    },
    browser: {
      name: mpProperties.$browser || null,
      version: mpProperties.$browser_version || null,
    },
    device: {
      name: mpProperties.$device || null,
    },
    screen: {
      height: mpProperties.$screen_height || null,
      width: mpProperties.$screen_width || null,
    },
    campaign: {
      name: campaignParams.utm_campaign,
      source: campaignParams.utm_source,
      medium: campaignParams.utm_medium,
      term: campaignParams.utm_term,
      content: campaignParams.utm_content,
    },
  };
};

function removeTerminalSuffix(str) {
  const suffix = ' - Terminal.io';
  if (str.endsWith(suffix)) {
    return str.slice(0, -suffix.length);
  }
  return str;
}

function convertCategory(str) {
  switch (str) {
    case 'businesses':
      return 'demand';
    case 'engineers':
      return 'supply';
    default:
      return str;
  }
}

function trackWithSegment({
  category,
  name,
  properties = {},
  context = {},
}: {
  category: 'businesses' | 'engineers';
  name: string;
  properties?: object;
  /**
   * When not provided, it will call getContext() and use its default values
   */
  context?: ReturnType<typeof getContext> | Record<string, never>;
}) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).analytics.page(
    convertCategory(category),
    removeTerminalSuffix(name),
    properties,
    {
      ...getContext(),
      ...context,
    },
  );
}

const SegmentContext = createContext<{
  canTrackUser: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  segmentPreferences: any;
  trackPage: (params: Parameters<typeof trackWithSegment>[number]) => void;
}>({
  canTrackUser: false,
  segmentPreferences: null,
  trackPage: trackWithSegment,
});

export function SegmentProvider({ children }) {
  const [canTrackUser, setCanTrackUser] = useState(false);
  const [segmentPreferences, setSegmentPreferences] = useState(null);

  const onReadyToInitializeTracking = useCallback((segmentPreferences) => {
    setSegmentPreferences(segmentPreferences);
  }, []);

  useEffect(() => {
    const segmentEvent = async () => {
      const consentRequired = await requiresConsent();
      setCanTrackUser(!consentRequired || segmentPreferences?.customPreferences?.functional);
    };

    if (segmentPreferences != null) {
      void segmentEvent();
    }
  }, [segmentPreferences]);

  return (
    <>
      <SegmentConsent onReadyToInitializeTracking={onReadyToInitializeTracking} />
      <SegmentContext.Provider
        value={{
          canTrackUser,
          segmentPreferences,
          trackPage: trackWithSegment,
        }}
      >
        {children}
      </SegmentContext.Provider>
    </>
  );
}

export function useSegment() {
  const context = useContext(SegmentContext);

  if (!context) {
    throw new Error('useSegment must be used within a SegmentProvider');
  }

  return context;
}
