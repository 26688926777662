import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import { Box, Center, Container, Heading } from '@terminal/design-system';
import type { BannerStoryblok, Storyblok } from '../types';
import { selectBlokStyles, selectResponsiveStyle } from '@helpers/storyblok.utils';
import { SquarePatternRoundedBG } from '@components/UI/SquarePattern';

export function Banner(props: Storyblok<BannerStoryblok>) {
  const { blok, page } = props;

  const marginTop = selectResponsiveStyle(blok.marginTop);
  const marginBottom = selectResponsiveStyle(blok.marginBottom);
  const paddingY = selectResponsiveStyle(blok.paddingY) || { base: 8, lg: 16 };
  const paddingX = selectResponsiveStyle(blok.paddingX) || { base: 6, lg: 10 };

  const margin: Record<string, string | string[]> = {
    marginTop: marginTop,
    marginBottom: marginBottom,
    marginLeft: 'auto',
    marginRight: 'auto',
  };

  const padding = {
    paddingTop: paddingY,
    paddingBottom: paddingY,
    paddingLeft: paddingX,
    paddingRight: paddingX,
  };

  const titleTextAlign = selectResponsiveStyle(blok.titleTextAlign) || 'center';
  const contentAlign = selectResponsiveStyle(blok.contentAlign) || 'center';
  const { paddingStyles, marginStyles } = selectBlokStyles(blok);

  if (!!paddingStyles.paddingBottom || !!paddingStyles.paddingTop) {
    padding.paddingTop = paddingStyles.paddingTop || '0';
    padding.paddingBottom = paddingStyles.paddingBottom || '0';
  }

  if (!!paddingStyles.paddingLeft || !!paddingStyles.paddingRight) {
    padding.paddingLeft = paddingStyles.paddingLeft || '0';
    padding.paddingRight = paddingStyles.paddingRight || '0';
  }

  if (!!marginStyles.marginTop || !!marginStyles.marginBottom) {
    margin.marginTop = marginStyles.marginTop || '0';
    margin.marginBottom = marginStyles.marginBottom || '0';
  }

  if (!!marginStyles.marginLeft || !!marginStyles.marginRight) {
    margin.marginLeft = marginStyles.marginLeft || '0';
    margin.marginRight = marginStyles.marginRight || '0';
  }

  const ContentWrapper = contentAlign === 'center' ? Center : Box;
  const PotentialContainer = blok.layoutType === 'fullWidth' ? Box : Container;

  const dotted = {
    right: {
      show: blok.showRightDottedBackground,
      width: selectResponsiveStyle(blok.rightDottedWidth),
      height: selectResponsiveStyle(blok.rightDottedHeight),
      top: selectResponsiveStyle(blok.rightDottedTopPosition),
      right: selectResponsiveStyle(blok.rightDottedRightPosition),
      bottom: selectResponsiveStyle(blok.rightDottedBottomPosition),
      left: selectResponsiveStyle(blok.rightDottedLeftPosition),
    },
    left: {
      show: blok.showLeftDottedBackground,
      width: selectResponsiveStyle(blok.leftDottedWidth),
      height: selectResponsiveStyle(blok.leftDottedHeight),
      top: selectResponsiveStyle(blok.leftDottedTopPosition),
      right: selectResponsiveStyle(blok.leftDottedRightPosition),
      bottom: selectResponsiveStyle(blok.leftDottedBottomPosition),
      left: selectResponsiveStyle(blok.leftDottedLeftPosition),
    },
  };

  return (
    <Box
      borderRadius={blok.borderRadius || 'none'}
      border={`${blok.borderSize || 0}px solid`}
      borderColor={blok.borderColor}
      bgColor={blok.color.value || 'grey.100'}
      as="section"
      marginTop={marginTop}
      marginBottom={marginBottom}
      {...padding}
      {...storyblokEditable(blok)}
      pos="relative"
      overflow="hidden"
    >
      <PotentialContainer pos="relative" zIndex={1}>
        {blok.title && (
          <Heading
            as="h2"
            // @ts-ignore
            textAlign={titleTextAlign}
            // @ts-ignore
            color={blok?.titleColor?.value}
            variant="heading-2"
            mb={{ base: 6, lg: 8 }}
          >
            {blok.title}
          </Heading>
        )}
        <ContentWrapper flexDir="column">
          {(blok.content || [])?.map((content) => (
            <StoryblokComponent blok={content} key={content._uid} page={page} />
          ))}
        </ContentWrapper>
      </PotentialContainer>
      {dotted.right.show && (
        <SquarePatternRoundedBG
          display={{ base: 'none', lg: 'initial' }}
          pos="absolute"
          top={dotted.right.top || '-50%'}
          bottom={dotted.right.bottom || '0'}
          left={dotted.right.left || 'initial'}
          right={dotted.right.right || '0'}
          w={dotted.right.width || '20%'}
          h={dotted.right.height || '94%'}
        />
      )}
      {dotted.left.show && (
        <SquarePatternRoundedBG
          display={{ base: 'none', lg: 'initial' }}
          pos="absolute"
          top={dotted.left.top || '50%'}
          bottom={dotted.left.bottom || '0'}
          left={dotted.left.left || '0'}
          right={dotted.left.right || 'initial'}
          w={dotted.left.width || '20%'}
          h={dotted.left.height || '94%'}
        />
      )}
    </Box>
  );
}
